import { createGlobalStyle } from 'styled-components';

import { layoutWrapper } from '@mixins';
import { fontL, fontM, fontS, fontXL, fontXS, fontXXL, fontXXXL } from '@fonts';
import mq from '@mq';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    line-height: 1.25;
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-family);
    /* height: 100%; */
    /* width: 100%; */
    
    -webkit-font-smoothing: antialiased;
    /* ms-overflow-style: none; */
    /* scrollbar-width: none; */
    overscroll-behavior-y: none;

    ${mq.hd} {
      font-size: 20px;
    }

    ${mq.fourK} {
      font-size: 40px;
    }
  }

  /* ::-webkit-scrollbar {
    display: none;
  } */

  main, footer, nav {
  }

  main {
    ${fontXS};
    text-wrap: balance;

    ${mq.small} {
      ${fontS};
    }
  }

  h1 {
    ${fontXL};

    ${mq.small} {
      ${fontXXL};
    }

    ${mq.medium} {
      ${fontXXXL};
    }
  }

  h2 {
    ${fontL};

    ${mq.small} {
      ${fontXL}
    }
  }
  
  section {
    ${layoutWrapper};
  }
`;

export default Base;
