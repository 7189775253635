import { hover } from '@mq';
import { css, keyframes } from 'styled-components';

const layoutWrapper = css`
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
`;

const grid = ({ gutter = true }) => css`
  ${gutter && layoutWrapper};
  display: grid;
  column-gap: var(--grid-gap);
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
`;

const hyphenate = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: justify;
`;

const truncate = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

const linkHover = css`
  ${hover} {
    transition: opacity var(--motion);
    &:hover {
      opacity: 0.6;
    }
  }
`;

const richText = css`
  p {
    white-space: pre-line;
    z-index: 1;
    position: relative;
  }

  p + p {
    margin-top: 1em;
  }

  //first paragraph after an h2 or h3
  h2 + p,
  h3 + p,
  h4 + p {
    margin-top: 0.75rem;
  }

  h2 {
    margin-top: 1em;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin-top: 3rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 + h3 {
    margin-top: 0;
  }

  h2 + h3 {
    margin-top: 1.5rem;
  }

  h4 {
    margin-top: 2rem;
  }

  ul {
    margin-top: 0.75rem;

    li {
      display: flex;

      &:before {
        content: '•';
        display: block;
        margin: 0 1rem;
        height: 100%;
      }
    }
  }

  a {
    text-decoration: underline;
  }
`;

const appearKeyframes = keyframes`
  from {
    opacity:  0;
  }

  to {
    opacity:  1;
  }
`;

const appear = css`
  animation: ${appearKeyframes} 1s var(--motion-delay, 0s) backwards;
`;

const transition = {
  transform: css`
    transform: translate3d(0, 0, 0);
    transition: transform var(--motion) ease-out;
  `,
};

export {
  richText,
  grid,
  truncate,
  layoutWrapper,
  center,
  hyphenate,
  cover,
  appear,
  transition,
  linkHover,
};
