import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #000;
    --color-white: #fff;
    --color-navigation: var(--color-white);


    --color-background: #F7F5F3;
    --color-text: #1F2723;
    --color-highlight: #E9F73B;

    --page-margin: 1.5rem; //24
    
    ${mq.small} {
      --page-margin: 2.25rem; //36
    }
    
    ${mq.medium} {
      --page-margin: 3.75rem; //60
    }

    --navigation-height: 4.5625rem;
    
    ${mq.small} {
      --navigation-height: 5.4375rem;
    }

    --font-family: 'IBM Plex Sans', sans-serif;
    
    ${mq.small} {
      
    }

    ${mq.medium} {
      
    }
    
    ${mq.large} {
      
    }
    
    --motion-half: 100ms;
    --motion: 300ms;
    --motion-double: 400ms;
    --motion-triple: 600ms;
    --motion-quadruple: 800ms;
    --motion-quintuple: 1000ms;
  }
`;

export default Vars;
