import { css } from 'styled-components';

const fontXS = css`
  font-size: 1rem; //16
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.005em;
`;

const fontS = css`
  font-size: 1.1875rem; //19
  font-weight: 400;
  line-height: 1.37;
  letter-spacing: 0.005em;
`;

const fontM = css`
  font-size: 1.875rem; //30
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.005em;
`;

const fontL = css`
  font-size: 2.25rem; //36
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: 0.028em;
  text-transform: uppercase;
`;

const fontXL = css`
  font-size: 3.5rem; //56
  font-weight: 500;
  line-height: 1.07;
  letter-spacing: 0.028em;
  text-transform: uppercase;
`;

const fontXXL = css`
  font-size: 6rem; //96
  font-weight: 500;
  line-height: 1.02;
  letter-spacing: 0.028em;
  text-transform: uppercase;
`;

const fontXXXL = css`
  font-size: 7rem; //112
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.028em;
  text-transform: uppercase;
`;

export { fontXS, fontS, fontM, fontL, fontXL, fontXXL, fontXXXL };
