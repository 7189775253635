import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ pageTitle, pageDescription, pageImage }) => {
  const {
    site: { buildTime, siteMetadata },
  } = useStaticQuery(graphql`
    query SeoQuery {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          title
          description
          pathPrefix
          siteTitle
          siteTitleAlt
          siteTitleShort
          siteHeadline
          siteUrl
          siteLanguage
          defaultSeoImage
          siteDescription
          author
          keywords
          ogSiteName
          ogLanguage
          backgroundColor
        }
      }
    }
  `);

  const realPrefix = siteMetadata.pathPrefix === '/' ? '' : siteMetadata.pathPrefix;
  const homeURL = `${siteMetadata.siteUrl}${realPrefix}`;

  const title = siteMetadata.siteTitle;
  const description = siteMetadata.siteDescription;

  const image = getSeoImage({
    homeURL,
    pageImage,
    defaultSeoImage: siteMetadata.defaultSeoImage,
  });

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: homeURL,
    headline: description,
    inLanguage: 'en',
    mainEntityOfPage: homeURL,
    description,
    name: title,
    author: {
      '@type': 'Person',
      name: title,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: title,
    },
    copyrightYear: new Date().getYear(),
    creator: {
      '@type': 'Person',
      name: title,
    },
    publisher: {
      '@type': 'Person',
      name: title,
    },
    datePublished: '2019-07-01',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: image,
    },
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': homeURL,
        name: 'Homepage',
      },
      position: 1,
    },
  ];

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  const facebookSeo = [
    { property: 'og:locale', content: siteMetadata.ogLanguage },
    { property: 'og:url', content: homeURL },
    { property: 'og:site_name', content: title },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:image:alt', content: description },
  ];

  const twitterSeo = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:url', content: homeURL },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    { name: 'twitter:image:alt', content: description },
  ];

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: siteMetadata.siteLanguage }}
      meta={[
        { name: 'title', content: title },
        { name: 'description', content: description },
        { name: 'keywords', content: siteMetadata.keywords },
        { name: 'image', content: image },
        { name: 'msapplication-TileColor', content: siteMetadata.backgroundColor },
        { name: 'msapplication-config', content: 'browsersiteMetadata.xml' },
        {
          name: 'google-site-verification',
          content: '4TZFvYVKFC8VP-RvqBrxwR3WWIYIF7mcDK2PWjRd67I',
        },
        ...facebookSeo,
        ...twitterSeo,
      ]}
    >
      <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  );
};

function getSeoImage({ homeURL, pageImage, defaultSeoImage }) {
  return `${homeURL}${pageImage || defaultSeoImage}`;
}

export default Seo;
